import { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { ShiftMaintenanceConstant } from "./ShiftMaintenanceConstant";
const SCREEN_CONSTANT = ShiftMaintenanceConstant;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
let timeFieldList: string[] = [];
export const INITIAL_SHIFT_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,

    },
    {
        headerName: SCREEN_CONSTANT.SHIFT_TYPE,
        field: "shiftType",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },
    {
        headerName: SCREEN_CONSTANT.SHIFT_DAY,
        field: "shiftDay",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 180,
    },
    {
        headerName: SCREEN_CONSTANT.START_TIME,
        field: "startTimeStr",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
        dataType: "time",
    },
    {
        headerName: SCREEN_CONSTANT.END_TIME,
        field: "endTimeStr",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
        dataType: "time",
    },
    {
        headerName: SCREEN_CONSTANT.AHEAD_TIME,
        field: "aheadTimeStr",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 140,
        dataType: "time",
    },
    {
        headerName: SCREEN_CONSTANT.EXTEND_TIME,
        field: "extendTimeStr",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 140,
        dataType: "time",
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
    {
        headerName: SCREEN_CONSTANT.SHIFT_CODEIS,
        field: "shiftCode",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    }

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, timeFieldList, cellRenderers);
});

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, timeFieldList, externalFnctions);
}

